<template>
  <div class="xuqiu_toutiao">
    <div class="xuqiu">
      <div class="xuqiu_header">
        <div class="tixing_xuqiu_parent">
          <div class="tixing">需求大厅</div>
        </div>
      </div>
      <div class="xuqiu_content" style="border-right: none">
        <ul>
          <div style="display: flex">
            <div>
              <div :class="{'select_span_index':nowIndex==index}" @click="changeNowItem(index)" class="li_title" v-for="(item,value,index) in xuqiu_list" :key="index"
                   style="display: block;height: 75px;line-height: 75px;cursor: pointer;text-align: left;padding-left: 20px">
                {{ value }}
              </div>
            </div>
            <div>
              <div class="li_content" style="border-right: 1px solid #f5f5f5;">
                  <div class="li_content" style="cursor: pointer;display: block;line-height: 75px">
                    <div style="float: left;display: block;padding-left: 20px">热门需求</div>
                    <div style="display:block;float: right;color: black;cursor: pointer;padding-right: 20px" @click="goDemandHall">更多>></div>
                  </div>
              </div>
              <div class="li_content" v-for="(item,index) in nowShowItem" :key="item.id" style="border-right: 1px solid #f5f5f5;border-left: 1px solid #f5f5f5">
                <router-link
                  :to="{ path: '/home/demandDetail', query: { id: item.id } }"
                  target="_blank"
                  style="color: #333333"
                  >
                  <div class="li_content" style="cursor: pointer">
                    <div
                        style="
                      height: 60px;
                      width: 500px;
                      background-color: white;
                      overflow: hidden;
                      "
                    >
                      <div
                          class="xuqiu_content_left ellipse-1"
                          style="padding-left: 20px;"
                      >
                        {{ item.title }}
                      </div>
                      <div
                          class="xuqiu_content_left ellipse-1"
                          style="padding-left: 20px; color: #666666;"
                      >
                        {{ item.content }}
                      </div>
                    </div>
                    <div
                        style="
              height: 60px;
              width: 200px;
              background-color: white;
              flex-shrink: 0;
              "
                    >
                      <div class="xuqiu_content_left" style="text-align: right;border-right: 1px solid #f5f5f5">
                        预算金额：<span
                          style="
                  color: rgb(252, 152, 64);
                  font-family: 'Microsoft YaHei';
                  "
                      >协商确定</span
                      >
                      </div>
                      <div
                          class="xuqiu_content_left"
                          style="
                font-size: 12px;
                border-right: 1px solid #f5f5f5;
                text-align: right;
                font-size: 12px;

                color: rgb(102, 102, 102);
                font-family: 'Microsoft YaHei';
                "
                      >
                        {{ item.updatedTime.split(" ")[0] }}
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
    <div class="toutiao">
      <div class="toutiao_header">
        <div class="tixing_xuqiu_parent">
          <div class="tixing">头条资讯</div>
          <span
              style="float: right; display: inline-block; cursor: pointer"
              @click="goBaike"
          >更多>></span
          >
        </div>
      </div>
      <div class="toutiao_content">
        <ul>
          <div v-for="(item, index) in news_list" :key="index" class="move-shadow">
            <router-link
                :to="{ path: '/home/knowledgeDetail', query: { id: item.id } }"
                target="_blank"
            >
              <li>
                <span
                    style="
                    font-size: 14px;
                    font-family: 'Microsoft YaHei';
                    color: rgb(50, 50, 50);
                    width: 300px;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                ><i
                    class="iconfont iconnews ellipse-1"
                    style="padding-right: 20px;color: #666666"
                ></i
                >{{ item.title }}</span
                ><span
                  style="
                    float: right;
                    font-size: 12px;
                    font-family: 'Microsoft YaHei';
                    color: rgb(102, 102, 102);
                  "
              >[{{ item.updatedTime }}]</span
              >
              </li>
            </router-link>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getIndexInformationList,
  getIndexRequirementData,
} from "../../../plugins/api/indexApi";
import {getKnowledgeListById} from "../../../plugins/api/encyclopediaKnowledgeApi";

export default {
  name: "demandAndInformation",
  data() {
    return {
      nowIndex:0,
      nowShowItem: [],
      xuqiu_list: [],
      news_list: [],
    };
  },
  mounted() {
    this.getRequirementList();
    this.getKnowledgeList();
  },
  methods: {
    changeNowItem(index) {
      this.nowIndex = index
      this.nowShowItem = this.xuqiu_list[Object.keys(this.xuqiu_list)[index]].length>6?this.xuqiu_list[Object.keys(this.xuqiu_list)[index]].slice(0,6):this.xuqiu_list[Object.keys(this.xuqiu_list)[index]]
    },
    goDemandHall() {
      this.$router.push({
        path: "/home/DemandHall",
      });
    },
    async getRequirementList() {
      const json = await getIndexRequirementData();
      if (json && json.code === 0) {
        if (json.result) {
          if (json.result.length > 5) {
            this.xuqiu_list = json.result.slice(0, 5);
          } else {
            this.xuqiu_list = json.result
          }
        }
      }
      this.nowShowItem = this.xuqiu_list[Object.keys(this.xuqiu_list)[0]].length>5?this.xuqiu_list[Object.keys(this.xuqiu_list)[0]].slice(0,5):this.xuqiu_list[Object.keys(this.xuqiu_list)[0]]


    },

    goBaike() {
      this.$router.push({
        path: "/home/baike",
        query:{
          tab:1,
        }
      });
    },

    //根据文章分类编号获取文章列表
    async getKnowledgeList() {
      this.knowledgeListLoading = true;
      let params = {
        pageSize: 9,
        pageNum: 1,
        articleTypeId: 1,
      };
      const json = await getIndexInformationList(params);
      if (json && json.code === 0) {
        if (json.result && json.result.list) {
          this.news_list = json.result.list;
          this.news_list.forEach((item)=> {
            item.updatedTime = item.updatedTime.split(" ")[0]
          })
        }
      }
    },
  },
};
</script>

<style>
.xuqiu {
  width: 700px;
  box-sizing: border-box;
  float: left;
}

.toutiao {
  width: 480px;
  box-sizing: border-box;
  float: right;
}

.xuqiu_toutiao {
  width: 1200px;
  margin: 0 auto;
  height: 525px;
}

.xuqiu_header,
.toutiao_header {
  height: 75px;
  display: flex;
  align-items: center;
}

.toutiao_header {
  width: 480px;
}

.xuqiu_header {
  width: 700px;
}

.tixing_xuqiu_parent {
  width: 700px;
  height: 40px;
  border-bottom: 1px solid #1890FF;
  line-height: 40px;
}
.move-shadow:hover {
  box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
}

.tixing {
  color: white;
  padding-left: 20px;
  width: 140px;
  height: 40px;
  background-color: #fc7c40;
  line-height: 40px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  font-size: 20px;
}

.tixing:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 40px solid white;
  border-left: 25px solid transparent;
  position: absolute;
  right: 0;
}

.xuqiu_content ul,
.toutiao_content ul {
  list-style: none;
}

.xuqiu_content ul li {
  display: flex;
  height: 75px;
  line-height: 75px;
}

.li_title {
  text-align: center;
  width: 140px;
  font-size: 16px;
  font-family: "Microsoft YaHei", serif;
  color: rgb(51, 51, 51);
  border-right: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  box-sizing: border-box;
}

.li_content {
  width: 560px;
  border-bottom: 1px solid #f5f5f5;
  box-sizing: border-box;
  height: 75px;
  display: flex;
  align-items: center;
}

.xuqiu_content_left {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  flex: 1;
  box-sizing: border-box;
  padding-right: 20px;
}

.xuqiu_content ul,
.toutiao_content ul {
  list-style: none;
}

.toutiao_content ul li {
  height: 50px;
  width: 450px;
  line-height: 50px;
  border-bottom: 1px solid #f5f5f5;
  margin: 0 auto;
  box-sizing: border-box;
}

.xuqiu_content {
  height: 450px;
  width: 700px;
  border: 1px solid #f5f5f5;
  border-bottom: none;
}

.toutiao_content {
  height: auto;
  width: 480px;
  border: 1px solid #f5f5f5;
}
.select_span_index {
  color: white;
  border-bottom: 2px solid #1890FF;
background-color: #1890FF;

}
</style>
